import * as Sentry from "@sentry/nuxt";
import pkg from "./package.json";

const env = import.meta.env.VITE_MODE;

if (env !== "local") {
    Sentry.init({
        dsn: "https://f6fd1f35a95fe2c35a7349dfc5af4fbd@o4507386020233216.ingest.us.sentry.io/4507386023116800",
        tracesSampleRate: 1.0,
        environment: env === "prod" ? "production" : "development",
        release: pkg.version || ""
    });
}
